import $ from 'jquery';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';

export default function initBlogLayout() {
  const elem = document.querySelector('.blog-grid');

  if (elem) {
    let iso = new Isotope(elem, {
      itemSelector: '.news-element',
      percentPosition: true,
      masonry: {
        columnWidth: '.grid-sizer'
      }
    });

    document.addEventListener('lazybeforeunveil', function () {
      imagesLoaded(elem, function () {
        iso.layout();
      });
    });

    $('.news').on('click', '.page-navigation a', function (e) {
      e.preventDefault();
      $(this).hide();
      let ajaxUrl = $(this).attr('href');
      $('.page-navigation').append(
        '<span class="loading"><svg width="34px" height="34px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" class="lds-rolling"><circle cx="50" cy="50" fill="none" stroke="#007C57" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138" transform="rotate(55.1984 50 50)"><animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform></circle></svg></span>'
      );
      if (ajaxUrl !== undefined && ajaxUrl !== '') {
        e.preventDefault();
        $.ajax({
          url: ajaxUrl,
          type: 'GET',
          success: function (result) {
            let ajaxDom = $(result).find('.blog-list');
            let nextLink = $(result).find('.page-navigation');
            $('.page-navigation').replaceWith(nextLink);
            elem.appendChild(ajaxDom[0]);
            iso.appended(ajaxDom[0]);
            iso.layout();
          }
        });
      }
    });
  }
}

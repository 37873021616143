import $ from 'jquery';

const quicklinks = $('.quicklinks')[0];
const bannerHorizontal = '.quicklinks-banner-horizontal';
const bannerBadges = $('.quicklinks-banner-badges');
const bannerBadgesItems = '.quicklinks-banner-badges .quicklinks-banner-badges__item > a';
const sliderItem = '.container-feature';
const menuWrapper = '.quicklinks-button-wrapper';
const quicklinksButton = $(menuWrapper).parent();
const button = `${menuWrapper} > span`;
const scrollHeight = $(bannerHorizontal).is(':visible')
  ? parseInt($(sliderItem).height()) + parseInt($(bannerHorizontal).height())
  : 0;
const content = $('#content')[0];
const items = $(bannerBadgesItems).toArray();
let stickyPosition;

export default function initQuicklinksBanner() {
  var timeout;
  var initialized = false; // initially open menu
  const openMenu = () => $(quicklinksButton).addClass('open');
  const closeMenu = () => $(quicklinksButton).removeClass('open');
  const toggleMenu = () => $(quicklinksButton).toggleClass('open');
  const hasQuicklinksBadges = bannerBadges && bannerBadges.length && quicklinks;

  if (hasQuicklinksBadges) {
    setSticky();
    $(window).resize(() => setSticky());
  }

  $(window).scroll(() => update());
  update();

  $(menuWrapper).on('mouseenter', () => {
    // open menu on mouse enter
    clearTimeout(timeout);
    if (initialized) {
      $(button).off('click');
      openMenu();
      setTimeout(() => $(button).on('click', toggleMenu), 10); // toggle menu on click (for mobile use)
    } else {
      // handle click while initializing (first 4 seconds)
      $(button).on('click', toggleMenu);
      initialized = true;
    }
  });

  $(menuWrapper).on('mouseleave', closeMenu); // close menu on mouse leave

  function setSticky() {
    if (!$(quicklinks).hasClass('sticky')) {
      let prevSibling = $(quicklinks).prev();
      stickyPosition = prevSibling[0].offsetTop + prevSibling.outerHeight();
    }
  }

  function update() {
    if ($(window).scrollTop() >= scrollHeight) {
      // show quickmenu button
      $(quicklinksButton).addClass('active');

      if (!initialized) {
        // initially open menu (for 4 seconds)
        openMenu();
        timeout = setTimeout(() => {
          closeMenu();
          initialized = true;
        }, 4000);
      }
    } else {
      // hide quickmenu button on the top of the page
      clearTimeout(timeout);
      $(quicklinksButton).removeClass('active open');
    }

    if (hasQuicklinksBadges) {
      setSticky();

      if (window.pageYOffset > +stickyPosition) {
        quicklinks.classList.add('sticky');
        content.classList.add('sticky-padding');

        const firstInView = items.find(link => $(link.hash).parent()().isInViewport());
        if (firstInView) {
          items.forEach(el => el.classList.add('grey'));
          firstInView.classList.remove('grey');
        } else {
          items.forEach(el => el.classList.remove('grey'));
        }
      } else {
        quicklinks.classList.remove('sticky');
        content.classList.remove('sticky-padding');
        items.forEach(el => el.classList.remove('grey'));
      }
    }
  }
}

$.fn.isInViewport = function () {
  if ($(this).offset()) {
    const elementTop = $(this).offset().top;
    const elementBottom = elementTop + $(this).outerHeight();

    const viewportTop = $(window).scrollTop();
    const viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
  } else {
    return false;
  }
};

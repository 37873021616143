export default function initLocationsDropdown() {
  const activeItem = document.querySelector('.locations__nav-item.active');
  const container = document.querySelector('.locations__nav-pages');
  const filterOpener = document.querySelector('.locations__nav-item-filter');
  const navArrowLocations = document.querySelector('.icon-nav-arrow--locations');
  const navArrowFilter = document.querySelector('.icon-nav-arrow--filter');

  if (container) {
    activeItem.addEventListener('click', function (e) {
      e.preventDefault();
      navArrowLocations.classList.toggle('icon-nav-arrow--up');
    });

    container.addEventListener('click', function () {
      container.classList.toggle('open');
    });
  }

  if (filterOpener) {
    filterOpener.addEventListener('click', function () {
      // we need to make filter selection here, otherwise element is possibly null
      const filterContainer = document.querySelector('.filter-container');
      if (filterContainer) {
        navArrowFilter.classList.toggle('icon-nav-arrow--up');
        filterContainer.classList.toggle('open');
      }
    });
  }
}

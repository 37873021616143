/* eslint-disable */
const select = $('#location-search-input');
const button = $('#nearby-search-btn');
const regionsEndpoint = '?type=530';

export default function initLocationSearch() {
  if (select.length) {
    select.select2({
      theme: 'classic',
      width: 'style',
      minimumInputLength: 2,
      matcher: matchCustom,
      placeholder: nearBySearchPlaceholder
    });
    select.on('select2:select', e => {
      button[0].classList.remove('disabled');
    });
    initButton();
    loadData();
  }
}

function matchCustom(params, data) {
  if ($.trim(params.term) === '') {
    return data;
  }

  if (typeof data.text === 'undefined') {
    return null;
  }

  if (isInt(params.term)) {
    if (data.text.indexOf(params.term) == 0) {
      var modifiedData = $.extend({}, data, true);

      return modifiedData;
    }
  } else {
    if (data.id.indexOf(params.term.toLowerCase()) == 0) {
      var modifiedData = $.extend({}, data, true);

      return modifiedData;
    }
  }

  return null;
}

function isInt(value) {
  var x;
  return isNaN(value) ? !1 : ((x = parseFloat(value)), (0 | x) === x);
}

function initButton() {
  button[0].classList.add('disabled');
  button.click(function (e) {
    e.preventDefault();
    if ($(this).hasClass('disabled')) {
      return;
    }
    const data = select.select2('data');
    if (data.length) {
      const option = data[0];
      const values = option.id.split(':');
      const zipcode = values[1];
      const community = values[2];
      window.location = `${$(this).attr('href')}#?location=${zipcode}_${community}`;
    }
  });
}

function loadData() {
  $.get(regionsEndpoint, data => {
    const regions = data.regions;
    const distinctData = [];
    const selectOptions = [];
    regions.forEach(region => {
      const distinctValue = `${region.zip}_${region.community}`;
      if (!distinctData.includes(distinctValue)) {
        distinctData.push(distinctValue);
        const id = `${region.community.toLowerCase()}:${region.zip}:${encodeURI(region.community)}`;
        selectOptions.push(new Option(`${region.zip} - ${region.community}`, id, false, false));
      }
    });
    select.append(selectOptions);
  });
}

/*! Select2 4.1.0-rc.0 | https://github.com/select2/select2/blob/master/LICENSE.md */

!(function () {
  if (jQuery && jQuery.fn && jQuery.fn.select2 && jQuery.fn.select2.amd) var e = jQuery.fn.select2.amd;
  e.define('select2/i18n/de', [], function () {
    return {
      errorLoading: function () {
        return 'Die Ergebnisse konnten nicht geladen werden.';
      },
      inputTooLong: function (e) {
        return 'Bitte ' + (e.input.length - e.maximum) + ' Zeichen weniger eingeben';
      },
      inputTooShort: function (e) {
        return 'Bitte ' + (e.minimum - e.input.length) + ' Zeichen mehr eingeben';
      },
      loadingMore: function () {
        return 'Lade mehr Ergebnisse…';
      },
      maximumSelected: function (e) {
        var n = 'Sie können nur ' + e.maximum + ' Element';
        return 1 != e.maximum && (n += 'e'), (n += ' auswählen');
      },
      noResults: function () {
        return 'Kein Treffer';
      },
      searching: function () {
        return 'Suche…';
      },
      removeAllItems: function () {
        return 'Entferne alle Elemente';
      }
    };
  }),
    e.define,
    e.require;
})();
/* eslint-disable */

import $ from 'jquery';

export default function initProductTeaserSlider() {
  $('.product-teaser-slider').each((num, el) => {
    el = $(el);

    const defaultSlides = 3;

    const slickSlider = el.slick();
    const slideCount = slickSlider.slick('getSlick').slideCount;
    const slidesToShow = slideCount < defaultSlides ? slideCount : defaultSlides;

    el.slick('unslick');

    el.slick({
      speed: 500,
      infinite: true,
      slidesToScroll: 1,
      slidesToShow,
      pauseOnHover: true,
      prevArrow:
        '<button type="button" class="product-teaser-slider__navigation product-teaser-slider__navigation__prev"></button>',
      nextArrow:
        '<button type="button" class="product-teaser-slider__navigation product-teaser-slider__navigation__next"></button>',
      responsive: [
        {
          breakpoint: 1430,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1.5,
            arrows: false
          }
        }
      ]
    });
  });
}

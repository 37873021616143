export default function initQuicklinksDropdown() {
  let qucklinksWrapper = document.querySelectorAll('[data-quicklinks-select-wrapper]');
  let select = document.getElementById('quicklinks-dropdown');

  if (select !== null) {
    for (let index = 0; index < qucklinksWrapper.length; index++) {
      let titleSelector = qucklinksWrapper[index].querySelector('[data-select-title]');
      let linkSelector = qucklinksWrapper[index].querySelector('[data-select-link]');

      if (titleSelector && linkSelector) {
        let title = titleSelector.getAttribute('data-select-title');
        let link = linkSelector.getAttribute('href');

        if (title && link) {
          let option = document.createElement('option');
          option.text = title;
          option.value = link;
          select.add(option);
        }
      }
    }
    select.selectedIndex = '0';

    window.onQuicklinkSelect = e => {
      window.location = e.value;
    };
  }
}

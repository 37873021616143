import '@fengyuanchen/datepicker/i18n/datepicker.de-DE.js';
import moment from 'moment';
import 'moment/locale/de';
import $ from 'jquery';

const showErrorMessageClass = 'vacation-insurance-form__error--show';
const showBookingDateFromClass = 'vacation-insurance-form__from--show';
const hideBookingDateClass = 'vacation-insurance-form__row--hidden';
const startDatepickerId = '#insurance_start-input';
const endDatepickerId = '#insurance_end-input';
const bookingDatepickerId = '#insurance_booking-date';
const bookingDateRowId = '#booking-date-row';
const submitButtonId = '#vacation-insurance-submit';
const checkboxId = '#checkbox-cancellation';
const bookingDateFromId = '#bookingDate-from';
const infoReadFormId = '#info-read';
const today = moment().startOf('day');
const tomorrow = moment().startOf('day').add(1, 'day');
const format = 'DD.MM.YYYY';
const dateRegex = new RegExp('^[0-3][0-9].[0-1][0-9].[1-2][0-9]{3}$', 'i');
let cancellation = false;
let startDate = null;
let endDate = null;
let bookingDate = null;
let maxDuration = 31;
let errorsCounter = 0;
let infoRead = false;

const errorIDs = {
  startDate: {
    errorDateInPast: '#startDate-errorDateInPast',
    errorDateFormat: '#startDate-errorDateFormat',
    errorDateInFarFuture: '#startDate-errorDateInFarFuture'
  },
  endDate: {
    errorDateInPast: '#endDate-errorDateInPast',
    errorDateFormat: '#endDate-errorDateFormat',
    errorEndDateBeforeStartDate: '#endDate-errorEndDateBeforeStartDate',
    errorStartDateEqualsEndDate: '#endDate-errorStartDateEqualsEndDate',
    errorDurationLongerThan365Days: '#endDate-errorDurationLongerThan365Days'
  },
  bookingDate: {
    errorDateInPast: '#bookingDate-errorDateInPast',
    errorDateFormat: '#bookingDate-errorDateFormat',
    warningBookingInPastMoreThan31Days: '#bookingDate-warningBookingInPastMoreThan31Days',
    errorBookingInPastLessEqual31Days: '#bookingDate-errorBookingInPastLessEqual31Days'
  },
  infoRead: {
    errorNotChecked: '#infoRead-notChecked'
  }
};
let datepickerConf = {
  autoHide: true,
  format: format,
  language: 'de-DE',
  weekStart: 1
};

export default function initVacationInsuranceForm() {
  const lang = $('html').attr('lang') === 'de' ? 'de-DE' : 'en-GB';
  cancellation = $(checkboxId).prop('checked');
  toggleCancellation();
  validateStartDate($(startDatepickerId).val());
  validateEndDate($(endDatepickerId).val());
  validateBookingDate($(bookingDatepickerId).val());

  datepickerConf.language = lang;

  datepickerConf.endDate = getMaxDate(today);

  // Initialize datepickers
  datepickerConf.trigger = '#calendar-icon-booking';
  $(bookingDatepickerId).datepicker(datepickerConf);

  datepickerConf.startDate = today.format(format);
  datepickerConf.trigger = '#calendar-icon-start';
  $(startDatepickerId).datepicker(datepickerConf);

  datepickerConf.endDate = null;

  datepickerConf.startDate = tomorrow.format(format);
  datepickerConf.trigger = '#calendar-icon-end';
  $(endDatepickerId).datepicker(datepickerConf);

  $(submitButtonId).click(function () {
    validateReadInfo();
    validateStartDate($(startDatepickerId).val());
    validateEndDate($(endDatepickerId).val());
    validateBookingDate($(bookingDatepickerId).val());
  });

  // Show / hide booking date
  $(checkboxId).change(function () {
    cancellation = this.checked;
    toggleCancellation();
  });

  $(infoReadFormId).change(function () {
    validateReadInfo();
  });

  // Handle booking date errors
  $(bookingDatepickerId)
    .on('blur', function () {
      const newDate = $(bookingDatepickerId).val();
      validateBookingDate(newDate);
    })
    .on('pick.datepicker', function (e) {
      validateBookingDate(moment(e.date));
    });

  // Handle start date errors
  $(startDatepickerId)
    .on('blur', function () {
      const newDate = $(startDatepickerId).val();
      validateStartDate(newDate);
      $(endDatepickerId).datepicker('setDate', getEndDate(toMoment(newDate)));
      $(endDatepickerId).datepicker('setStartDate', getEndDate(toMoment(newDate)));
    })
    .on('pick.datepicker', function (e) {
      validateStartDate(moment(e.date));
      $(endDatepickerId).datepicker('setDate', getEndDate(e.date));
      $(endDatepickerId).datepicker('setStartDate', getEndDate(e.date));
    });

  // Handle end date errors
  $(endDatepickerId)
    .on('blur', function () {
      const newDate = $(endDatepickerId).val();
      validateEndDate(newDate);
    })
    .on('pick.datepicker', function (e) {
      validateEndDate(moment(e.date));
    });
}

function getEndDate(date) {
  let dateEnd = moment(date).add(1, 'day').format(format);
  return dateEnd;
}

function getMaxDate(date) {
  let dateMax = moment(date).add(1, 'year').format(format);
  return dateMax;
}

function toggleCancellation() {
  if (cancellation) {
    $(bookingDateRowId).removeClass(hideBookingDateClass);
    $(bookingDatepickerId).attr('required', 'true');
  } else {
    $(bookingDatepickerId).val('');
    validateBookingDate(null);
    $(bookingDateRowId).addClass(hideBookingDateClass);
    $(bookingDatepickerId).removeAttr('required');
  }
}

function addError(errorId) {
  $(errorId).addClass(showErrorMessageClass);

  if (errorId != errorIDs.bookingDate.warningBookingInPastMoreThan31Days) {
    $(submitButtonId).prop('disabled', true);
    errorsCounter += 1;
  } else {
    $(bookingDateFromId).addClass(showBookingDateFromClass);
    $(bookingDateFromId + ' > span').text(moment().startOf('day').add(10, 'days').format(format));
  }
}

function removeError(errorId) {
  if ($(errorId).hasClass(showErrorMessageClass)) {
    $(errorId).removeClass(showErrorMessageClass);
    if (errorId != errorIDs.bookingDate.warningBookingInPastMoreThan31Days) {
      errorsCounter -= 1;
    } else {
      $(bookingDateFromId).removeClass(showBookingDateFromClass);
    }
  }
  if (errorsCounter == 0) {
    $(submitButtonId).prop('disabled', false);
  }
}

// Converts string formatted as dd.mm.yyyy to moment object
function toMoment(dateString) {
  if (dateString) {
    const splitted = dateString.split('.');
    return moment([splitted[2], splitted[1] - 1, splitted[0]]);
  } else {
    return null;
  }
}

function getValidDate(newDate, errorId) {
  removeError(errorId);

  if (typeof newDate === 'string') {
    if (dateRegex.test(newDate)) {
      const momentDate = toMoment(newDate);
      if (momentDate.isValid()) {
        return momentDate;
      }
    } else if (newDate === '') {
      return null;
    }
  } else if (moment.isMoment(newDate) && newDate.isValid()) {
    return newDate;
  } else if (newDate == null) {
    return null;
  }

  addError(errorId);
  return null;
}

function validateBookingDate(newDate) {
  bookingDate = getValidDate(newDate, errorIDs.bookingDate.errorDateFormat);

  removeError(errorIDs.bookingDate.warningBookingInPastMoreThan31Days);
  removeError(errorIDs.bookingDate.errorBookingInPastLessEqual31Days);

  if (bookingDate && startDate) {
    let startDiff = Math.abs(today.diff(startDate, 'days'));
    let bookingInPast = bookingDate.isBefore(today, 'day');

    if (startDiff >= 0 && bookingInPast) {
      if (startDiff > maxDuration) {
        addError(errorIDs.bookingDate.warningBookingInPastMoreThan31Days);
      } else {
        addError(errorIDs.bookingDate.errorBookingInPastLessEqual31Days);
      }
    }
  }
}

function validateReadInfo() {
  infoRead = $(infoReadFormId).prop('checked');
  removeError(errorIDs.infoRead.errorNotChecked);
  if (!infoRead) {
    addError(errorIDs.infoRead.errorNotChecked);
  }
}

function validateStartDate(newDate) {
  removeError(errorIDs.startDate.errorDateInPast);
  removeError(errorIDs.startDate.errorDateInFarFuture);
  startDate = getValidDate(newDate, errorIDs.startDate.errorDateFormat);
  let startDiff = Math.abs(today.diff(startDate, 'days'));

  if (bookingDate) {
    validateBookingDate(bookingDate);
  }
  if (endDate) {
    validateEndDate(endDate);
  }

  if (startDate && startDate.isBefore(today, 'day')) {
    addError(errorIDs.startDate.errorDateInPast);
  } else if (startDate && startDiff >= 365) {
    addError(errorIDs.startDate.errorDateInFarFuture);
  }
}

function validateEndDate(newDate) {
  removeError(errorIDs.endDate.errorDateInPast);
  removeError(errorIDs.endDate.errorEndDateBeforeStartDate);
  removeError(errorIDs.endDate.errorStartDateEqualsEndDate);
  removeError(errorIDs.endDate.errorDurationLongerThan365Days);
  endDate = getValidDate(newDate, errorIDs.endDate.errorDateFormat);

  if (endDate) {
    if (endDate.isBefore(today, 'day')) {
      addError(errorIDs.endDate.errorDateInPast);
    }

    if (startDate) {
      let duration = Math.abs(endDate.diff(startDate, 'days'));

      if (endDate.isBefore(startDate, 'day')) {
        addError(errorIDs.endDate.errorEndDateBeforeStartDate);
      } else if (endDate.isSame(startDate, 'day')) {
        addError(errorIDs.endDate.errorStartDateEqualsEndDate);
      } else if (duration >= 0 && duration > 364) {
        addError(errorIDs.endDate.errorDurationLongerThan365Days);
      }
    }
  }
}

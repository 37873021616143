const closeAfter = 5000;

export default function initOverlayText() {
  const container = document.querySelector('.overlay-text');

  if (container) {
    setTimeout(() => {
      container.classList.add('fadeOut');
    }, closeAfter);
  }
}

import $ from 'jquery';
import locale2 from 'locale2';

function getCookie(cname) {
  var name = cname + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1);
    if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
  }
  return '';
}

function createModalCookie() {
  var modal = document.getElementById('lang__modal');
  document.cookie = 'lang-modal=disabled; path=/; expires=' + setExpiryDate() + ';';
  modal.classList.add('hidden');
}

function setExpiryDate() {
  var d = new Date();
  d.setFullYear(d.getFullYear() + 1);
  return d.toUTCString();
}

export default function checkBrowserLanguage() {
  const modalPresent = document.getElementById('lang__modal__wrapper');
  const cuntryCode = locale2.substr(0, 2);
  const isCookieSet = getCookie('lang-modal');

  if (modalPresent && isCookieSet === '') {
    const currentLang = document.getElementById('lang__modal__wrapper').getAttribute('data-language');
    if (cuntryCode != currentLang) {
      const contentPid = document.getElementById('lang__modal__wrapper').getAttribute('data-pid');
      const pagetype = 'type=456';
      const pageid = 'id=' + contentPid;
      $.ajax({
        url: '/?' + pageid + '&' + pagetype,
        dataType: 'html',
        success: function (data) {
          const $response = $(data);
          let modalContent = $response.find('#' + cuntryCode).html();
          if (!modalContent) {
            modalContent = $response.find('#fallback').html();
          }
          $('#lang__modal .modal-content-result').append(modalContent);

          if (isCookieSet === '') {
            if (cuntryCode != currentLang && $('.modal-content-result').text().length != 0) {
              $('#lang__modal').show();
            }
            document.getElementById('lang__modal__close').addEventListener('click', createModalCookie);
            const btn1 = document.getElementById('btn-lang');
            if (btn1) {
              btn1.addEventListener('click', createModalCookie);
            }
            const btn2 = document.getElementById('btn-service');
            if (btn2) {
              btn2.addEventListener('click', createModalCookie);
            }
            const btn3 = document.getElementById('btn-modal-close');
            if (btn3) {
              btn3.addEventListener('click', createModalCookie);
            }
          }
        }
      });
    }
  }
}

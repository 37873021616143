import $ from 'jquery';
import { open3rdLevelMenu } from './header';

export default function initA11nNavigation() {
  // open up 2nd level menu
  $('.level-1-a button').on('keydown', function (e) {
    if (e.code !== 'Space' && e.code !== 'Enter') return;
    e.preventDefault();

    const closestListItem = $(this).closest('li');
    const isMobile = $('.header__navigation-toggle').hasClass('open');
    if (closestListItem.hasClass('has-submenu')) {
      closestListItem.toggleClass('nav-opened');
      const level2 = closestListItem.find('.level-2');

      if (isMobile) return;

      // close other level2 menus if open on desktop
      if (closestListItem.hasClass('nav-opened')) {
        updateTabIndices(level2, true);
        $('.nav-opened').not(closestListItem).removeClass('nav-opened');
        $('.sub-opened').not(closestListItem).removeClass('sub-opened');
        $('.dropdown--2-levels').not(closestListItem.find('.dropdown--2-levels')).css('display', 'none');
        closestListItem.find('.dropdown--2-levels').css('display', 'block');
      } else {
        closestListItem.find('.dropdown--2-levels').css('display', 'none');
      }
    }
  });

  // open up 3rd level menu
  $('.level-2 button').on('keydown', function (e) {
    if (e.code !== 'Space' && e.code !== 'Enter') return;
    e.preventDefault();
    const activeLevel2Item = $(this).parent();

    if (activeLevel2Item.hasClass('has-submenu')) {
      const activeLevel2Id = activeLevel2Item.attr('id');
      const currentFirstMenuItem = $(`#${activeLevel2Id}-content li:first-child a`);
      const currentLastMenuItem = $(`#${activeLevel2Id}-content li:last-child a`);
      const generalLevel3LastMenuItem = $('.level-3 .level-3-container:last-child li:last-child a');
      const isMobile = $('.header__navigation-toggle').hasClass('open');

      open3rdLevelMenu(activeLevel2Item, false);
      if (!isMobile) updateTabIndices(`#${activeLevel2Id}-content`, true);

      let prevButton = '';
      activeLevel2Item.on('keydown', function (e) {
        // if level 3 is opened, we want to continue to first menu item
        if (!isMobile && e.code === 'Tab' && (prevButton === 'Space' || prevButton === 'Enter')) {
          e.preventDefault();
          currentFirstMenuItem.trigger('focus');
        }
        prevButton = e.code;
      });

      currentLastMenuItem.on('keydown', function (e) {
        // after last item of current level3 menu is tabbed, jump back to next level2 item
        if (!currentLastMenuItem.is(generalLevel3LastMenuItem)) {
          if (e.code === 'Tab' && !e.shiftKey) {
            e.preventDefault();
            const nextLevel2Item = activeLevel2Item.parent().next().find('a');
            nextLevel2Item.trigger('focus');
          }
        }
      });
    }
  });
}

// Add loop within mobile menu
$('nav').on('keydown', function (e) {
  const isMobile = $('.header__navigation-toggle').hasClass('open');
  if (e.key === 'Tab' && !e.shiftKey && isMobile) {
    const lastTabableMobileMenuItem = $('nav').find('a').last();
    const navToggle = $('.header__navigation-toggle');
    if ($(document.activeElement).is(lastTabableMobileMenuItem)) {
      e.preventDefault();
      navToggle.trigger('focus');
    }
  }
});

export function updateTabIndices(container, shouldAddTabIndex = true) {
  const tabableElements = $(container).find('a');

  tabableElements.each(function () {
    if (shouldAddTabIndex) {
      $(this).attr('tabindex', '0');
    } else {
      $(this).attr('tabindex', '-1');
    }
  });
}

function pushSearchEvent(eventName, serachAction, fieldId) {
  // eslint-disable-next-line
  dataLayer.push({
    event: eventName,
    'suche-action': serachAction,
    'suche-term': document.getElementById(fieldId).value
  });
}

export default function initAnalytics() {
  // Modal search Themensuche
  if (document.getElementById('topicSearchButton')) {
    document.getElementById('topicSearchButton').addEventListener('click', function () {
      pushSearchEvent('interne-suche', 'ThemensucheNavtest', 'topicSearchInputField');
    });
  }

  // Modal search Standort-/Kundenberatersuche
  if (document.getElementById('customerSearchButton')) {
    document.getElementById('customerSearchButton').addEventListener('click', function () {
      pushSearchEvent('interne-suche', 'StandortsucheNav', 'consultantSearchInputField');
    });
  }

  // Serach page
  if (document.getElementById('kesearch_submit')) {
    document.getElementById('kesearch_submit').addEventListener('click', function () {
      pushSearchEvent('interne-suche', 'ThemensucheSearch', 'ke_search_sword');
    });
  }

  // Nearby CE
  if (document.getElementById('nearby-search-btn')) {
    document.getElementById('nearby-search-btn').addEventListener('click', function () {
      pushSearchEvent('interne-suche', 'StandortsucheWidget', 'location-search-input');
    });
  }

  // Beratersuche
  if (document.getElementById('consultant-serach-btn')) {
    document.getElementById('consultant-serach-btn').addEventListener('click', function () {
      pushSearchEvent('interne-suche', 'StandortsucheSearch', 'consultant-serach-input');
    });
  }
}

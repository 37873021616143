/**
 * File for google analytics advanced tracking.
 * Add or extend this file for more tracking options.
 * Use the function ga(...) to send data to google analytics.
 */

/**
 * The category to group the tracked data in the google analytis dashboard.
 * @type {string}
 */
const CATEGORY = window.location.pathname;

/**
 * Function to track events
 *
 * @param action The event action.
 * @param label The event label.
 * @param value Additional value.
 * @returns {*}
 */
function gaTrackEvent(action, label, value) {
  if (!CATEGORY || !action || !label) {
    return;
  }
  // eslint-disable-next-line
  ga('send', 'event', CATEGORY, action, label, value, { nonInteraction: true });
}

/**
 * Function to track click events
 *
 * @param label The event label.
 * @param value Additional value.
 */

// eslint-disable-next-line
function gaTrackClickEvent(label, value) {
  gaTrackEvent('click', label, value);
}

// Vendor scripts
import '@fengyuanchen/datepicker';
import 'picturefill';
import './polyfills/dataset';
import './polyfills/array-from';
import './polyfills/foreach';
import './polyfills/includes';
import './polyfills/url-search-params-polyfill';
import './polyfills/array-includes';
import 'what-input';
import 'bootstrap/js/transition';
import 'bootstrap/js/collapse';
import 'lazysizes';
import 'select2';
import 'select2/dist/js/i18n/hr.js';
import 'select2/dist/js/i18n/bs.js';
import 'select2/dist/js/i18n/uk.js';

// Our scripts
import initAnalytics from './components/analytics';
import initContentSlider from './components/content-slider';
import initCookieBar from './components/cookie-bar';
import initFooter from './components/footer';
import { initForms, initPreventEmptySubmitForms, initFormDatalayer } from './components/forms';
import initNavigation from './components/header';
import initQuicklinksDropdown from './components/icon-quicklinks-dropdown';
import initLocationInputs from './components/location-input';
import initNumberedTextBox from './components/numbered-textbox-ce';
import initPolizzenInputs from './components/polizzen-input';
import initPortraitImages from './components/portrait-images-ce';
import initQuicklinksBanner from './components/quicklinks-banner';
import initSearch from './components/search';
import initSlider from './components/slider';
import initVacationInsuranceForm from './components/vacation-insurance-form';
import checkBrowserLanguage from './components/lang-detector';
import initBrandHistory from './components/brand-history';
import initBlogLayout from './components/blog-layout';
import initOverlayText from './components/overlay-text';
import initLocationsDropdown from './components/locations-nav';
import initLocationSearch from './components/locations-search';
import initProductTeaserSlider from './components/product-teaser-slider';

// other JS
import './components/ga-advanced-tracking';
import './components/mail-decrypt';
import initAccordion from './components/accordion';

initContentSlider();
initCookieBar();
initFooter();
initForms();
initFormDatalayer();
initNavigation();
initQuicklinksDropdown();
initLocationInputs();
initNumberedTextBox();
initPolizzenInputs();
initPortraitImages();
initQuicklinksBanner();
initSearch();
initSlider();
initVacationInsuranceForm();
checkBrowserLanguage();
initAnalytics();
initBrandHistory();
initBlogLayout();
initOverlayText();
initPreventEmptySubmitForms();
initLocationsDropdown();
initLocationSearch();
initProductTeaserSlider();
initAccordion();

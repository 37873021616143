export default function initLocationInputs() {
  let geo = true;
  if (navigator && !navigator.geolocation) {
    geo = false;
  }

  const inputs = document.getElementsByClassName('location-btn');

  for (let i = 0; i < inputs.length; i++) {
    if (geo) {
      inputs[i].addEventListener('click', getPosition);
    } else {
      inputs[i].style.display = 'none';
    }
  }

  initAutocomplete();
}

function getPosition(e) {
  const geoOptions = {
    maximumAge: 5 * 60 * 1000,
    timeout: 10 * 1000
  };

  e.target.parentNode.classList.remove('loc-error');
  e.target.parentNode.classList.add('loc-loading');
  navigator.geolocation.getCurrentPosition(onGeoSuccess, onGeoError, geoOptions);
}

// If we have a successful location update
function onGeoSuccess(location) {
  var spinners = document.getElementsByClassName('loc-loading');
  for (let i = 0; i < onGeoSuccess.length; i++) {
    spinners[i].classList.remove('loc-loading');
  }

  const latitude = location.coords.latitude;
  const longitude = location.coords.longitude;
  initAddress(latitude, longitude);
}

// If something has gone wrong with the geolocation request
function onGeoError() {
  let inputs = document.getElementsByClassName('loc-loading');
  for (let i = 0; i < inputs.length; i++) {
    inputs[i].classList.add('loc-error');
    inputs[i].classList.remove('loc-loading');
  }
}

function initAddress(latitude, longitude) {
  // eslint-disable-next-line
  var geocoder = new google.maps.Geocoder();
  var latlng = { lat: latitude, lng: longitude };
  geocoder.geocode({ location: latlng }, function (results, status) {
    if (status === 'OK') {
      if (results[0]) {
        const locationInputs = document.getElementsByClassName('location-field');
        let i;
        for (i = 0; i < locationInputs.length; i++) {
          locationInputs[i].value = results[0].formatted_address;
        }
      } else {
        window.alert('No results found');
      }
    } else {
      window.alert('Geocoder failed due to: ' + status);
    }
  });
}

function initAutocomplete() {
  const locationInputs = document.getElementsByClassName('location-field');
  let i;
  for (i = 0; i < locationInputs.length; i++) {
    // eslint-disable-next-line
    const autocomplete = new google.maps.places.Autocomplete(locationInputs[i]);

    autocomplete.addListener('place_changed', function () {
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        return;
      }
    });
  }
}

import '@fengyuanchen/datepicker/i18n/datepicker.de-DE.js';
import $ from 'jquery';
// Fix for tooltips overlapped by navbar

export function initForms() {
  var elements = document.querySelectorAll('input,textarea');

  const captchaReloadButton = document.querySelector('.captcha__reload');

  if (captchaReloadButton) {
    setTimeout(function () {
      captchaReloadButton.click();
    }, 1000);
  }

  for (var i = elements.length; i--; ) {
    elements[i].addEventListener('invalid', invalidListener);
    elements[i].addEventListener('change', changeListener);
  }

  $('.DatePicker input[type="text"]').datepicker({
    format: $(this).data('format')
  });
}

var invalidListener = function () {
  this.scrollIntoView(false);
  let el = next(this);
  if (el) {
    el.classList.add('invalid');
  }
};

var changeListener = function () {
  let elements = document.querySelectorAll('.invalid');
  for (var i = 0; i < elements.length; i++) {
    elements[i].classList.remove('invalid');
  }
};

function next(elem) {
  do {
    elem = elem.nextSibling;
  } while (elem && elem.nodeType !== 1);
  return elem;
}

/* eslint-disable */
export function initPreventEmptySubmitForms() {
  let searchModal = document.querySelector('.search-wrapper__content');
  document.querySelectorAll('[data-no-empty-submit-form]').forEach(form => {
    const inputs = form.querySelectorAll('[data-no-empty-input]');
    const submitButton = form.querySelector('input[type="submit"]');

    inputs.forEach(input => {
      input.addEventListener('keyup', () => {
        if (!input.value) {
          submitButton.disabled = true;
        } else {
          submitButton.disabled = false;
        }
      });
    });

    form.addEventListener('submit', e => {
      inputs.forEach(input => {
        if (!input.value) {
          e.preventDefault();
          input.focus();
        } else {
          setTimeout(function () {
            searchModal.innerHTML =
              '<div class="text-center"><svg class="modal-spinner" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff"> <g fill="none" fill-rule="evenodd"> <g transform="translate(1 1)" stroke-width="2"> <circle stroke-opacity=".5" cx="18" cy="18" r="18"/> <path d="M36 18c0-9.94-8.06-18-18-18"> <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"/></path></g></g></svg></div>';
          }, 200);
        }
      });
    });
  });
}

export function initFormDatalayer() {
  if (typeof formId !== 'undefined') {
    const form = document.querySelector(formId);

    form.addEventListener('submit', function () {
      const data = $(formId).serializeArray();
      addDataToDataLayer(prepareDataForGTM(data));
    });
  }

  function prepareDataForGTM(data) {
    const formArray = data.map(item => {
      return {
        field: item.name
          .replace('tx_form_formframework[' + formName + ']', '')
          .replace('[', '')
          .replace(']', '')
          .replace('[]', '_value')
          .replace('-', '_'),
        value: item.value
      };
    });

    const output = [];

    formArray.forEach(function (item) {
      let existing = output.filter(function (v, i) {
        return v.field == item.field;
      });
      if (existing.length) {
        let existingIndex = output.indexOf(existing[0]);
        output[existingIndex].value = output[existingIndex].value + ' | ' + item.value;
      } else {
        if (typeof item.value == 'string') item.value = item.value;
        output.push(item);
      }
    });

    const result = output.map(item => {
      return { [item.field]: item.value };
    });

    return result;
  }

  function addDataToDataLayer(data) {
    const flattenObject = obj =>
      Object.keys(obj).reduce((acc, k) => {
        if (typeof obj[k] === 'object') Object.assign(acc, flattenObject(obj[k], k));
        else acc[k] = obj[k];
        return acc;
      }, {});

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'formularsubmit',
      formname: formName,
      formdetail: flattenObject(data),
      action: steps
    });
  }
}

/* eslint-enable */

import $ from 'jquery';
import 'slick-carousel';
import 'lightgallery';

function changeSliderTextWidth() {
  if ($('.slider__item__textblock').length) {
    var textLeft = $('.slider__item__textblock').css('padding-left');
    textLeft = textLeft.split('.')[0];
    textLeft = textLeft.replace(/\D/g, '');
    textLeft = parseInt(textLeft, 10);
    $('.slider__item__textblock').width($(window).width() - textLeft);
  }
}

export default function initSlider() {
  const slickSlider = $('.slider').slick({
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 7000,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    pauseOnHover: false
  });

  const slideCount = slickSlider.slick('getSlick').slideCount;

  if (slideCount > 1) {
    $('.slider').append(
      '<div class="slider__controls"><svg class="slick-arrow slick-prev" width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="-button-/-Btn-Back" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <path d="M14,27.7781746 L21.7781746,20 L14,12.2218254 L16.1213203,10.1005051 L24.6066017,18.5857864 C25.3876503,19.366835 25.3876503,20.633165 24.6066017,21.4142136 L16.1213203,29.8994949 L14,27.7781746 Z" id="Path" fill="#FFFFFF" transform="translate(19.596194, 20.000000) scale(-1, 1) translate(-19.596194, -20.000000) "></path> </g> </svg><svg class="slick-arrow slick-next" width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <g id="-button-/-Btn-Next" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <path d="M14.8125,27.7781746 L22.5906746,20 L14.8125,12.2218254 L16.9338203,10.1005051 L25.4191017,18.5857864 C26.2001503,19.366835 26.2001503,20.633165 25.4191017,21.4142136 L16.9338203,29.8994949 L14.8125,27.7781746 Z" id="Path" fill="#FFFFFF"></path> </g> </svg></div>'
    );
    $('.slick-dots li').prepend('<div class="white-bar"></div>');
    $('.slick-next').click(function () {
      slickSlider.slick('slickNext');
      slickSlider.slick('slickPause');
      $('.slick-dots .slick-active').find('button').addClass('no-animation');
    });
    $('.slick-prev').click(function () {
      slickSlider.slick('slickPrev');
      slickSlider.slick('slickPause');
      $('.slick-dots .slick-active').find('button').addClass('no-animation');
    });
    $('.slick-dots li').click(function () {
      $(this).find('button').addClass('no-animation');
    });
    let active_dot = $('.slick-dots .slick-active');
    active_dot.removeClass('slick-active');
    setTimeout(function () {
      active_dot.addClass('slick-active');
    }, 0);
  }

  changeSliderTextWidth();

  window.addEventListener('resize', function () {
    changeSliderTextWidth();
  });

  $('.gallery').lightGallery({});

  var numSlick = 0;
  $('.slider-gallery').each(function () {
    numSlick++;
    $(this)
      .addClass('slider-' + numSlick)
      .slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        adaptiveHeight: false,
        prevArrow:
          '<button type="button" class="content-slider__navigation content-slider__navigation__preview slick-arrow" style=""></button>',
        nextArrow:
          '<button type="button" class="content-slider__navigation content-slider__navigation__next slick-arrow" style=""></button>',
        asNavFor: '.slider-nav.slider-' + numSlick
      });
  });

  numSlick = 0;
  $('.slider-nav').each(function () {
    numSlick++;
    $(this)
      .addClass('slider-' + numSlick)
      .slick({
        slidesToShow: 7,
        slidesToScroll: 1,
        centerPadding: '30px',
        asNavFor: '.slider-gallery.slider-' + numSlick,
        dots: false,
        arrows: false,
        centerMode: false,
        focusOnSelect: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 6
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 4
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 3
            }
          }
        ]
      });
  });
}

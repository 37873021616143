import $ from 'jquery';

function adjustNumberedTextboxHeight() {
  if ($(window).width() > window.breakpoints['md']) {
    $('.boxes').each(function () {
      var largest = 0;

      $(this)
        .find('.boxes__item')
        .each(function () {
          $(this).css({ height: 'auto' });
          var findHeight = $(this).height();

          if (findHeight > largest) {
            largest = findHeight;
          }
        });
      if ($(window).width() > window.breakpoints['md']) {
        $(this)
          .find('.boxes__item')
          .css({ height: largest + 'px' });
      }
    });
  }
}

export default function initNumberedTextBox() {
  adjustNumberedTextboxHeight();

  window.addEventListener('resize', adjustNumberedTextboxHeight);
}

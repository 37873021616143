import $ from 'jquery';

export default function initAccordion() {
  $('.accordion.focussing .panel-collapse').on('shown.bs.collapse', function () {
    let offset = 10;
    const quicklinks = $('.quicklinks.sticky');
    if (quicklinks.length) {
      offset += quicklinks.first().outerHeight();
    }
    const position = $(this).prev().offset().top;
    window.scrollTo({ top: position - offset, behavior: 'smooth' });
  });
}

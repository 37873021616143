import $ from 'jquery';

export default function initPolizzenInputs() {
  const fields = $('.PolizzenInput');

  if (fields.length) {
    fields.each(function () {
      if ($(this).find('.poldata').val()) {
        var data = $(this).find('.poldata').val().split('|');
        $(this).find('.polnr').val(data[0]);
        $(this).find('.kennz').val(data[1]);
      }

      $(this).find('.polnr').keyup(setPolizzenData);
      $(this).find('.kennz').keyup(setPolizzenData);
    });
  }
}

function setPolizzenData(e) {
  let parent = $(e.currentTarget).closest('.PolizzenInput');
  var val = parent.find('.polnr').val() + '|' + parent.find('.kennz').val();
  parent.find('.poldata').val(val);
}

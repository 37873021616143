import $ from 'jquery';
import noUiSlider from 'nouislider';
import Isotope from 'isotope-layout';
import wNumb from 'wnumb';
import 'lightgallery';
import imagesLoaded from 'imagesloaded';

export default function initBrandHistory() {
  const yearSlider = document.getElementById('year-slider');
  if (yearSlider) {
    const $yearFrom = $('.year-from');
    const $yearTo = $('.year-to');
    const elem = document.getElementById('grid');
    const start = parseInt(document.getElementById('range-start').innerHTML);
    const end = parseInt(document.getElementById('range-end').innerHTML);

    noUiSlider.create(yearSlider, {
      start: [start, end],
      tooltips: [wNumb({ decimals: 0 }), wNumb({ decimals: 0 })],
      connect: true,
      step: 1,
      range: {
        min: start,
        max: end
      },
      pips: {
        mode: 'range',
        density: 5
      }
    });

    let iso = new Isotope(elem, {
      itemSelector: '.grid-item',
      filter: function (itemElem) {
        return multipleFilter(itemElem);
      }
    });

    yearSlider.noUiSlider.on('update', function (values, handle) {
      (handle ? $yearTo : $yearFrom).text(parseInt(values[handle]));
      iso.arrange({
        filter: function (itemElem) {
          return multipleFilter(itemElem);
        }
      });
    });

    document.addEventListener('lazybeforeunveil', function () {
      imagesLoaded(elem, function () {
        iso.layout();
      });
    });

    $('.brand-history').lightGallery({
      controls: false,
      mousewheel: false,
      counter: false,
      download: false,
      preload: 0
    });

    // eslint-disable-next-line
    function multipleFilter(item) {
      function checkYear() {
        let year = item.querySelector('.year').innerText;
        let yearFrom = parseInt($yearFrom.text()) || 0;
        let yearTo = parseInt($yearTo.text()) || 0;
        year = parseInt(year) || 0;
        return year >= yearFrom && year <= yearTo;
      }
      return checkYear();
    }
  }
}

import $ from 'jquery';
function adjustPortraitImagesHeight() {
  if ($(window).width() > window.breakpoints['md']) {
    // Resize images height to the largest
    $('.portrait-images').each(function () {
      var largest = 0;

      $(this)
        .find('.portrait-images__item')
        .each(function () {
          $(this).css({ height: 'auto' });
          var findHeight = $(this).height();

          if (findHeight > largest) {
            largest = findHeight;
          }
        });
      if ($(window).width() > window.breakpoints['md']) {
        $(this)
          .find('.portrait-images__item')
          .css({ height: largest + 'px' });
      }
    });
    // Resize title and subtitles height to the largest so that all are at the same level
    $('.portrait-images').each(function () {
      var largest = 0;

      $(this)
        .find('.portrait-images__item__title')
        .each(function () {
          $(this).css({ height: 'auto' });
          var findHeight = $(this).height();

          if (findHeight > largest) {
            largest = findHeight;
          }
        });
      $(this)
        .find('.portrait-images__item__title')
        .css({ height: largest + 'px' });
    });
    $('.portrait-images').each(function () {
      var largest = 0;

      $(this)
        .find('.portrait-images__item__subtitle')
        .each(function () {
          $(this).css({ height: 'auto' });
          var findHeight = $(this).height();

          if (findHeight > largest) {
            largest = findHeight;
          }
        });
      $(this)
        .find('.portrait-images__item__subtitle')
        .css({ height: largest + 'px' });
    });
  }
}

export default function initPortraitImages() {
  adjustPortraitImagesHeight();
  window.addEventListener('resize', adjustPortraitImagesHeight);
}

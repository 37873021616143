import $ from 'jquery';

export default function initContentSlider() {
  $('.content-slider').slick({
    speed: 500,
    autoplay: true,
    autoplaySpeed: 4000,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    pauseOnHover: true,
    fade: true,
    prevArrow: '<button type="button" class="content-slider__navigation content-slider__navigation__preview"></button>',
    nextArrow: '<button type="button" class="content-slider__navigation content-slider__navigation__next"></button>',
    dotsClass: 'content-slider__dots'
  });

  // Lazy load youtube iframe and thumbnail images
  const wrappers = document.querySelectorAll('.iframe-wrapper');
  const cookieBanner = document.getElementById('cookie-bar');

  if (!wrappers.length) {
    return;
  }

  let allowYoutube = getCookie('cookie_tracking_status');
  let isGrawe = getCookie('grawe');

  if (isGrawe === 'true' || isGrawe === true || cookieBanner == null) {
    allowYoutube = true;
  }

  if (allowYoutube === '' || allowYoutube === false || allowYoutube === 'false') {
    $('.consentNotice').removeClass('hidden');
  }

  // Async load YouTube iFrame API
  let tag = document.createElement('script');
  tag.src = 'https://www.youtube.com/iframe_api';
  let firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  window.onPlayerStateChange = onPlayerStateChange;
  window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady();
  window.onPlayerReady = onPlayerReady;

  // store all YouTube players in a windows object
  window.players = {};
  const wwidth = $(window).width();

  for (let i = 0; i < wrappers.length; i++) {
    let alt = `${wrappers[i].dataset.alt}`;
    const videoType = `${wrappers[i].dataset.type}`;

    if (videoType === 'youtube') {
      // load preview thumbnail for video depending on windows size
      let source = `https://img.youtube.com/vi/${wrappers[i].dataset.embed}/mqdefault.jpg`;
      if (wwidth > 640) {
        source = `https://img.youtube.com/vi/${wrappers[i].dataset.embed}/maxresdefault.jpg`;
      } else if (wwidth > 480) {
        source = `https://img.youtube.com/vi/${wrappers[i].dataset.embed}/hqdefault.jpg`;
      }

      let image = new Image();
      image.src = source;
      image.alt = alt;

      image.addEventListener(
        'load',
        (function () {
          wrappers[i].appendChild(image);
        })(i)
      );
    }
  }
}

// Callback function after Youtube iFrame API has finished loading
function onYouTubeIframeAPIReady() {
  const wrappers = document.querySelectorAll('.iframe-wrapper');
  window.players = {};
  for (let i = 0; i < wrappers.length; i++) {
    const videoType = `${wrappers[i].dataset.type}`;
    if (videoType === 'youtube') {
      wrappers[i].addEventListener('click', function () {
        // eslint-disable-next-line
        dataLayer.push({
          event: 'videoklick',
          'video-url': 'https://www.youtube-nocookie.com/embed/' + this.dataset.embed
        });
        let allowYoutube = getCookie('cookie_tracking_status');
        const isGrawe = getCookie('grawe');
        const cookieBanner = document.getElementById('cookie-bar');
        if (allowYoutube == 'true' || cookieBanner == null || isGrawe == 'true') {
          // Pause slider
          $(wrappers[i]).closest('.content-slider').slick('slickPause').on('beforeChange', beforeContentSliderChange);

          // initialize Player
          // eslint-disable-next-line
          window.players[`player-${this.dataset.embed}`] = new YT.Player(`player-${this.dataset.embed}`, {
            videoId: this.dataset.embed,
            host: 'http://www.youtube-nocookie.com',
            events: {
              onReady: onPlayerReady,
              onStateChange: onPlayerStateChange
            },
            playerVars: {
              playlist: `${this.dataset.embed}`,
              rel: 0,
              loop: 1
            }
          });
        } else {
          $('.consentNotice').removeClass('hidden');
        }
      });
    }
  }
}

// Callback function after Youtube Player is ready
function onPlayerReady(event) {
  const wrapper = event.target.getIframe().parentNode.parentNode;
  wrapper.classList.add('loaded');
  event.target.playVideo();
  wrapper.classList.add('playing');
}

// Callback function for handling player state changes
function onPlayerStateChange(event) {
  // get parent content-slider__item
  const wrapper = event.target.getIframe().parentNode.parentNode;

  if (
    /* eslint-disable */
    event.data !== YT.PlayerState.PLAYING &&
    event.data !== YT.PlayerState.LOADING &&
    event.data !== YT.PlayerState.BUFFERING
    /* eslint-enable */
  ) {
    wrapper.classList.remove('playing');
  } else {
    wrapper.classList.add('playing');
  }
}

// eslint-disable-next-line
function beforeContentSliderChange(event, slick, currentSlide, nextSlide) {
  const id = slick.$slides.eq(currentSlide).find('.iframe-wrapper').data('embed');

  const player = window.players[`player-${id}`];
  if (player && typeof player.pauseVideo !== 'undefined') {
    player.pauseVideo();
  }
}

function getCookie(cname) {
  var name = cname + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1);
    if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
  }
  return '';
}

const searchModal = document.getElementById('searchModal');
const searchCloseButton = document.getElementById('close-search');
const searchField = document.getElementById('topicSearchInputField');
const searchModalOpen = 'search-modal--opened';

export default function initSearch() {
  const searchTriggers = document.getElementsByClassName('searchTrigger');

  for (let i = 0, len = searchTriggers.length; i < len; i++) {
    searchTriggers[i].onclick = displayModal;
    searchTriggers[i].addEventListener('keyup', function (event) {
      if (event.keyCode === 32 || event.keyCode === 13) {
        displayModal(event);
      }
    });
  }
}

function displayModal(event) {
  event.preventDefault();
  document.addEventListener('keyup', function (event) {
    if (event.keyCode === 27) {
      document.body.style.position = 'static';
      searchModal.classList.remove(searchModalOpen);
    }
  });

  let width = document.documentElement.clientWidth;
  searchModal.classList.add(searchModalOpen);
  searchField.focus();

  if (width < window.breakpoints['md']) {
    document.body.style.position = 'fixed';
  }
}

searchCloseButton.onclick = function () {
  document.body.style.position = 'static';
  searchModal.classList.remove(searchModalOpen);
};

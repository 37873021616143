import $ from 'jquery';

export default function initFooter() {
  var noHeader = '.footermenu__box:not(:has(header))';
  $(noHeader).each(function () {
    if ($(this).children().hasClass('frame-type-menu_pages')) {
      $(this).addClass('footermenu__box--no-header');
    }
  });
}

$(window).on('scroll', () => {
  if ($(window).width() > 1430) {
    if ($('.footerQuicklinks').isInViewport()) {
      $('.quicklinks-button-wrapper').css({ position: 'relative', bottom: '0', right: '0' });
    } else {
      $('.quicklinks-button-wrapper').css({ position: 'fixed', bottom: '20px', right: '55px' });
    }
  }
});
